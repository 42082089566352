import Head from 'next/head';
import { ReactNode } from 'react';

interface StructuredDataProps {
  '@context': string;
  '@type': string;
  identifier: string;
  description: string;
  url: string;
  yearBuilt: string;
  address: {
    '@type': string;
    streetAddress: string;
    addressLocality: string;
    addressRegion: string;
    postalCode: string;
    addressCountry: string;
  };
  image: string;
  numberOfRooms: string;
  numberOfBathroomsTotal: string;
  numberOfBedrooms: string;
  floorSize: {
    '@type': string;
    value: string;
    unitCode: string;
  };
  geo: {
    '@type': string;
    latitude: string;
    longitude: string;
  };
  latitude: string;
  longitude: string;
}

interface BreadcrumbListItem {
  '@type': string;
  position: number;
  name: string;
  item: string;
}
interface BreadcrumbList {
  '@context': string;
  '@type': string;
  itemListElement: BreadcrumbListItem[];
}

export interface MetaProps {
  title: string;
  description: string;
  keywords: string;
  ogImage: string;
  ogUrl: string;
  imageHeight?: string;
  imageWidth?: string;
  children?: ReactNode;
  structuredData?: StructuredDataProps[];
  breadcrumbList?: BreadcrumbList;
}

export const Meta = ({
  children,
  title,
  description,
  keywords,
  ogImage,
  ogUrl,
  imageHeight = '315',
  imageWidth = '315',
  structuredData,
  breadcrumbList,
}: MetaProps) => (
  <Head>
    <title>{title}</title>
    <meta name="description" content={description} />
    <link rel="canonical" href={ogUrl} />

    {/* Breadcrumbs */}
    {breadcrumbList && (
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(breadcrumbList) }}
      />
    )}

    {/* Structured data */}
    {structuredData?.map((data) => (
      <script
        key={data.url}
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
      />
    ))}

    {/* Open Graph metadata */}
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:url" content={ogUrl} />
    <meta property="og:type" content="website" />
    <meta property="og:image" content={ogImage} />
    <meta property="og:image:width" content={imageWidth} />
    <meta property="og:image:height" content={imageHeight} />

    {/* Twitter Card metadata */}
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:url" content={ogUrl} />
    <meta name="twitter:image" content={ogImage} />
    <meta property="og:image:width" content={imageWidth} />
    <meta property="og:image:height" content={imageHeight} />
    {children}
  </Head>
);
